.custom-radio-container {
  margin-left: 35px;
  margin-top: 10px;
  cursor: pointer;
}

.custom-radio::after {
  content: "";
  display: block;
  border: 1px solid #707070;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 0px;
  transform: translateX(-35px);
  margin-top: 2px;
  transition: all 0.2s ease-in;
}

.custom-radio:checked::after {
  background-color: #ffff6c;
  border-color: #1b2646;
}

.custom-radio {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-radio-level-container {
  cursor: pointer;
  margin-left: 50%;
  margin-top: -22px;
}

.custom-radio-level::after {
  content: "";
  display: block;
  border: 1px solid #707070;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-top: 2px;
  transform: translateX(-50%);
  transition: all 0.2s ease-in;
}

.custom-radio-level:checked::after {
  background-color: #ffff6c;
  border-color: #1b2646;
}

.custom-radio-level {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
}
